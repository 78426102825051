// import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import { i18nVue } from 'laravel-vue-i18n';
import * as Sentry from '@sentry/vue';

import './echo.js'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, App, props, plugin }) {

		const app = createApp({ render: () => h(App, props) });

		if (import.meta.env.VITE_APP_ENV !== 'local') {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				environment: import.meta.env.APP_ENV,
			});
		}

		app
			.use(plugin)
			.use(ZiggyVue, Ziggy)
			.use(i18nVue, {
				resolve: async lang => {
					const langs = import.meta.glob('../../lang/*.json');
					return await langs[`../../lang/${lang}.json`]();
				}
			})
			.mount(el);

		return app;
	},
	progress: {
		color: '#3383c6',
	},
});
